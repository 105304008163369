import React, { useState, useEffect } from 'react';

const defaultState = {
  lockScroll: false,
  overlayIsOpen: false,
}

const ThemeContext = React.createContext(defaultState)

const ThemeProvider = ({ children }) => {

  /**
   * TODO: lockScroll doesn't work.
   * The class is added, the overflow:hidden css is applied,
   * but the body still scrolls.
   * If you unckeck/recheck the overflow:hidden property in the inspector,
   * then scrolling is disabled.
   * 
   * end me
   */
  const [lockScroll, setLockScroll] = useState(defaultState.lockScroll);
  const [overlayIsOpen, setOverlayIsOpen] = useState(defaultState.overlayIsOpen);  // overlay state

  useEffect(() => {
    if(typeof document === 'undefined') { return false; }
    if(lockScroll) {
      document.documentElement.classList.add('lockScroll')
    } else {
      document.documentElement.classList.remove('lockScroll')
    }
  }, [lockScroll])

  return (
    <ThemeContext.Provider
      value={{
        ...defaultState,
        setLockScroll,
        overlayIsOpen,
        setOverlayIsOpen,
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}

export default ThemeContext

export { ThemeProvider }


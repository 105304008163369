import React, { useState } from "react"
import { ThemeProvider } from "./src/context/theme-context"
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import { StoreProvider } from "./src/context/store-context"
import { Helmet } from "react-helmet"
import { Script } from "gatsby"

 const MarkerIo = () => {
   if(typeof window === 'undefined') { return null }

   return (
     <Helmet>
       <script>
         {`window.markerConfig = {
           destination: '62fa5a00b9e2887d748f5a38',
           source: 'snippet'
         };`}
       </script>
       <script>
         {`!function(e,r,a){if(!e.__Marker){e.__Marker={};var t=[],n={__cs:t};["show","hide","isVisible","capture","cancelCapture","unload","reload","isExtensionInstalled","setReporter","setCustomData","on","off"].forEach(function(e){n[e]=function(){var r=Array.prototype.slice.call(arguments);r.unshift(e),t.push(r)}}),e.Marker=n;var s=r.createElement("script");s.async=1,s.src="https://edge.marker.io/latest/shim.js";var i=r.getElementsByTagName("script")[0];i.parentNode.insertBefore(s,i)}}(window,document);`}
       </script>
     </Helmet>
   )
 }

const StampedIo = () => {
  const [loaded, setLoaded] = useState(false);

  return (<>
    <Script
      src="https://cdn1.stamped.io/files/widget.min.js"
      onLoad={() => setLoaded(true)}
    />
    {loaded && (
      <Script>
        {`//<![CDATA[
          StampedFn.init({
            apiKey: '${process.env.GATSBY_STAMPED_PUBLIC_KEY}',
            storeUrl: 'lencosupplies.myshopify.com'
          });
        // ]]>`}
      </Script>
    )}
  </>)
}

const Gtag = () => {
  const [loaded, setLoaded] = useState(false);

  return (<>
    <Script
      src={`https://www.googletagmanager.com/gtm.js?id=${process.env.GATSBY_GTM}`}
      onLoad={() => setLoaded(true)}
    />
    {loaded && (
      <Script>
        {`
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({ 'gtm.start': new Date().getTime(), 'event': 'gtm.js' })
        `}
      </Script>
    )}
  </>)
}

// const GtagNoscript = () => {
//   return (
//     <noscript dangerouslySetInnerHTML={{ __html: `
//       <iframe
//         src="https://www.googletagmanager.com/ns.html?id=${process.env.GATSBY_GTM}"
//         height="0"
//         width="0"
//         style={{ display: 'none', visibility: 'hidden' }}
//         title="Google Tag Manager"
//       ></iframe>
//     `}} />
//   )
// }

const Tidio = () => {
  return (
    <Script src="//code.tidio.co/xznp1mdth2nekxygwulubane79dbdjxg.js" />
  )
}

const GoogleSiteVerification = () => {
  return (
    <Helmet>
      <meta
        name="google-site-verification"
        content="VJTsxhjrtjiq8TCLW9c3anv1OGTrmHkJHZaQw-Is5RA"
      />
    </Helmet>
  )
}

const VideoCampaign  = () => {
  return (
    <Script src='//pixel.mathtag.com/event/js?mt_id=1654266&mt_adid=429207&mt_exem=&mt_excl=&v1=&v2=&v3=&s1=&s2=&s3=' />
  )
}

const Gtmnew = () => {
  return (
    <Script>
      {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-P9CDNPR6');
      `}
    </Script>
  )
}

const Gtmothernew = () => {
  return (
    <Script>
      {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','GTM-M6SK6P');
      `}
    </Script>
  )
}

const Gtagnew = () => {
  return (
    <Script>
      {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
      
        gtag('config', 'AW-751630469');
      `}
    </Script>
  )
}

const Gtmnoscript = () => {
  return (
    <noscript dangerouslySetInnerHTML={{ __html: `
      <iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-P9CDNPR6"
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
        title="Google Tag Manager"
      ></iframe>
    `}} />
  )
}

const Gtmnoscriptnew = () => {
  return (
    <noscript dangerouslySetInnerHTML={{ __html: `
      <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-M6SK6P" height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `}} />
  )
}

const Tvsquared = () => {
  return (
    <Script>
      {`
        var _tvq = window._tvq = window._tvq || [];
        (function() {
            var u = (("https:" == document.location.protocol) ? "https://collector-15590.us.tvsquared.com/" : "http://collector-15590.us.tvsquared.com/");
            _tvq.push(['setSiteId', "TV-8145450990-1"]);
            _tvq.push(['setTrackerUrl', u + 'tv2track.php']);
            _tvq.push([function() {
                this.deleteCustomVariable(5, 'page')
            }]);
            _tvq.push(['trackPageView']);
            var d = document,
                g = d.createElement('script'),
                s = d.getElementsByTagName('script')[0];
            g.type = 'text/javascript';
            g.defer = true;
            g.async = true;
            g.src = u + 'tv2track.js';
            s.parentNode.insertBefore(g, s);
        })();
      `}
    </Script>
  )
}

const alertOptions = {
  position: positions.TOP_RIGHT,
  timeout: 5000,
  offset: '30px',
  transition: transitions.FADE,
  containerStyle: {
    zIndex: 99999
  }
}

const AlertTemplate = ({ style, options, message, close }) => (
  <div className={`Alert Alert--${options.type}`} style={style}>
    <div className="Alert__icon"></div>
    <div className="Alert__message">{message}</div>
    <button className="Alert__button notbutton" onClick={close} aria-label="Close alert"></button>
  </div>
)

const PPixel = () => {
  return (<>
    {/* Buffalo: */}
    <Script>
      {`var MathTag = {
        version: "1.1",
        delimiter: "[INSERT DELIMITER]",
        previous_url: document.referrer,
        mt_exem: "[INSERT SHA-256 HASHED EMAIL]",
        industry: "Home, Garden & Furniture",
        mt_adid: "429207",
        event_type: "catchall",
        mt_id: "1653163",
        order_id: "[INSERT MACRO]",
        product_brand: "[INSERT MACRO]",
        product_category: "[INSERT MACRO]",
        product_id: "[INSERT MACRO]",
        product_price: "[INSERT MACRO]",
        product_quantity: "[INSERT MACRO]",
        revenue: "[INSERT MACRO]"
      }`}
    </Script>
    <Script src="//pixel.mathtag.com/event/js?mt_pp=1&mt_adid=429207" />

    {/* Rochester: */}
    <Script>
      {`var MathTag = {
        version: "1.1",
        delimiter: "[INSERT DELIMITER]",
        previous_url: document.referrer,
        mt_exem: "[INSERT SHA-256 HASHED EMAIL]",
        industry: "Home, Garden & Furniture",
        mt_adid: "429207",
        event_type: "catchall",
        mt_id: "1653164",
        order_id: "[INSERT MACRO]",
        product_brand: "[INSERT MACRO]",
        product_category: "[INSERT MACRO]",
        product_id: "[INSERT MACRO]",
        product_price: "[INSERT MACRO]",
        product_quantity: "[INSERT MACRO]",
        revenue: "[INSERT MACRO]"
      }`}
    </Script>
    <Script src="//pixel.mathtag.com/event/js?mt_pp=1&mt_adid=429207" />
  </>)
}

export const wrapRootElement = ({ element }) => (
  <>
    {process.env.NODE_ENV === 'production' && (<>
      <GoogleSiteVerification />
      <Gtagnew />
      <Gtmnew />
      <Gtmothernew />
      <Gtag />
      {/* <GtagNoscript /> */}
      <Tidio />
      <Gtmnoscript />
      <Gtmnoscriptnew />
      <Tvsquared />
    </>)}
    <StampedIo />
    {process.env.GATSBY_ACTIVE_ENV === 'staging' && (
      <MarkerIo />
    )}
    <PPixel />
    <VideoCampaign />
    <AlertProvider template={AlertTemplate} {...alertOptions}>
      <ThemeProvider>
        <StoreProvider>
          {element}
        </StoreProvider>
      </ThemeProvider>
    </AlertProvider>
  </>
)
